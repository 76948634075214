var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('analytics-congratulation-john')],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('analytics-statistics-card')],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('analytics-weekly-overview')],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('analytics-card-total-earning')],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('statistics-card-profit-line-chart',{attrs:{"stat-title":_vm.profitLineChart.statTitle,"statistics":_vm.profitLineChart.statistics,"chart-series":_vm.profitLineChart.series,"chart-color":_vm.$vuetify.theme.currentTheme.success,"chart-height":"85"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('statistics-card-vertical',{attrs:{"change":_vm.totalProfitOptions.change,"color":_vm.totalProfitOptions.color,"icon":_vm.totalProfitOptions.icon,"statistics":_vm.totalProfitOptions.statistics,"stat-title":_vm.totalProfitOptions.statTitle,"subtitle":_vm.totalProfitOptions.subtitle}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('statistics-card-vertical',{attrs:{"change":_vm.newProjectOptions.change,"color":_vm.newProjectOptions.color,"icon":_vm.newProjectOptions.icon,"statistics":_vm.newProjectOptions.statistics,"stat-title":_vm.newProjectOptions.statTitle,"subtitle":_vm.newProjectOptions.subtitle}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('statistics-card-session-bar-chart',{attrs:{"stat-title":_vm.sessionBarChart.statTitle,"statistics":_vm.sessionBarChart.statistics,"chart-series":_vm.sessionBarChart.series,"chart-color":[
            _vm.$vuetify.theme.currentTheme.error,
            _vm.$vuetify.theme.currentTheme.primary,
            _vm.$vuetify.theme.currentTheme.error,
            _vm.$vuetify.theme.currentTheme.primary,
            _vm.$vuetify.theme.currentTheme.primary
          ],"chart-height":"80"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('analytics-performance')],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('analytics-card-deposit-and-withdraw')],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('analytics-card-sales-by-countries')],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('analytics-user-table')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }